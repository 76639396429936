<template>
	<div>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" />
		<b-card no-body>
			<b-tabs>
				<b-tab title="Primary Information" active>
					<b-card-text>
						<b-row class="mb-2">
							<b-col sm="8">
								<b-form-group label="Asset Code" label-cols-sm="4" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<span class="numFont">
										{{ selAsset.assetCode }}
									</span>
								</b-form-group>

								<b-form-group label="Asset Name" label-cols-sm="4" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									{{
										selAsset.details && selAsset.details.name
										? selAsset.details.name
										: '-'
									}}
								</b-form-group>

								<b-form-group label="Serial No." label-cols-sm="4" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<span class="numFont">
										{{
											selAsset.details && selAsset.details.serialNo
											? selAsset.details.serialNo
											: '-'
										}}
									</span>
								</b-form-group>

								<b-form-group label="Condition" label-cols-sm="4" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<b-badge v-if="selAsset.details && selAsset.details.condition === 'Good'"
										variant="success">
										Good
									</b-badge>
									<b-badge v-else-if="selAsset.details && selAsset.details.condition === 'Damaged'"
										variant="danger">
										Damaged
									</b-badge>
									<b-badge v-else variant="secondary">
										{{ getCondition(selAsset) }}
									</b-badge>
								</b-form-group>

								<b-form-group label="Net Book Value" label-cols-sm="4" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<span class="numFont">
										{{
											selAsset.details && selAsset.details.netBookValue
											? selAsset.details.netBookValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
											: '0.00'
										}} PHP
									</span>
								</b-form-group>

								<b-form-group v-show="getCategory(selAsset) !== '-'" label="Category:" label-cols-sm="4"
									label-class="font-weight-bold pt-0" label-align-sm="right">
									{{ getCategory(selAsset) }}
								</b-form-group>

								<b-form-group label="Status" label-cols-sm="4" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<AssetRowStatus :selAsset="selAsset" :showFull="true" />
								</b-form-group>

								<b-form-group label="Description" label-cols-sm="4" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<span v-if="getDescription(selAsset).length > 0" class="truncate-text">
										<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100"
											less="Show Less" type="html" :text="getDescription(selAsset)" />
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>
							<b-col sm="3" v-if="hasAssetPhoto(selAsset)">
								<b-img v-img-orientation-changer :src="getAssetPhotoUrl(selAsset)" alt="Responsive image"
									thumbnail fluid />
							</b-col>
						</b-row>
					</b-card-text>
				</b-tab>

				<b-tab title="Inventory Details">
					<b-card-text>
						<b-form-group label="Unit of Measure" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{
								selAsset.details && selAsset.details.uom
								? selAsset.details.uom
								: '-'
							}}
						</b-form-group>

						<b-form-group label="Unit Cost (PHP)" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span class="numFont">
								{{
									selAsset.details && selAsset.details.unitCost
									? selAsset.details.unitCost.toLocaleString("en-US") + ' PHP'
									: '-'
								}}
							</span>
						</b-form-group>

						<b-form-group label="Purchase Date" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{
								selAsset.details && selAsset.details.purchaseDate
								? showFormattedDate(selAsset.details.purchaseDate)
								: '-'
							}}
						</b-form-group>

						<b-form-group label="Remaining Shelf Life" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span class="numFont">
								{{ remainingShelfLife }}
							</span>
						</b-form-group>

						<b-form-group label="Expiration Date" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{
								selAsset.details && selAsset.details.expirationDate
								? showFormattedDate(selAsset.details.expirationDate)
								: '-'
							}}
						</b-form-group>
					</b-card-text>
				</b-tab>

				<b-tab title="Activation" v-if="selAsset.status !== 'Inactive' && selAsset.status !== 'Lost'">
					<b-card-text>
						<b-form-group label="Date Activated" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ showFormattedDateWithTime(selAsset.dateActivated) }}
						</b-form-group>

						<b-form-group label="Activated By" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ selAsset.activatedBy }}
						</b-form-group>

						<b-form-group label="Asset Age" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span class="numFont">
								{{ getNoOfDays(selAsset.dateActivated) }} day(s)
							</span>
						</b-form-group>

						<b-form-group v-show="selAsset.status === 'Stored'" label="Last Inventory" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ getLastInventory(selAsset) }}

						</b-form-group>
					</b-card-text>
				</b-tab>

				<b-tab title="Current Location" v-if="selAsset.status !== 'Inactive' && selAsset.status !== 'Lost'">
					<b-card-text>
						<b-form-group label="Company" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ currentLocation.company }}
						</b-form-group>

						<b-form-group label="Storage Location" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ currentLocation.storageLocation }}
						</b-form-group>

						<b-form-group v-show="selAsset.status != 'Lost'" label="Date Stored" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ showFormattedDateWithTime(currentLocation.dateStored) }}
						</b-form-group>

						<b-form-group v-show="selAsset.status != 'Lost'" label="Asset Tenure" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							<span class="numFont">
								{{ getNoOfDays(currentLocation.dateStored) }} day(s)
							</span>
						</b-form-group>

						<b-form-group v-show="selAsset.status === 'Lost'" label="Date Lost" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ showFormattedDateWithTime(currentLocation.dateLost) }}
						</b-form-group>

						<b-form-group v-show="selAsset.status === 'Lost'" label="No of Days Lost" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ getNoOfDays(currentLocation.dateLost) }} day(s)
						</b-form-group>
					</b-card-text>
				</b-tab>

				<b-tab title="Change Logs">
					<b-col sm="12" class="mt-2">
						<AssetChangeLogsDetailsView :row="row" />
					</b-col>
				</b-tab>
			</b-tabs>
		</b-card>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button v-show="isAllowedToActivate()" size="sm" v-b-modal.activate-asset
					v-b-tooltip.hover.top="'Activate Asset'" variant="success" @click.stop="updateSelAsset(selAsset)"
					class="mr-1">
					<i class="fa fa-bolt"></i>
				</b-button>

				<b-button v-show="isAllowedToUpdate()" size="sm" v-b-modal.update-asset-details
					v-b-tooltip.hover.top="'Update Asset Details'" variant="warning" @click.stop="updateSelAsset(selAsset)"
					class="mr-1">
					<i class="fa fa-pencil"></i>
				</b-button>

				<b-button v-show="isAllowedToUpdate() && !isAccounting" size="sm" v-b-modal.mark-as-lost-asset
					v-b-tooltip.hover.top="'Mark as Lost'" variant="danger" @click.stop="updateSelAsset(selAsset)"
					class="mr-1">
					<i class="fa fa-ban"></i>
				</b-button>

				<!-- <b-button v-show="isAllowedToUpdate()" size="sm" v-b-modal.change-asset-location
					v-b-tooltip.hover.top="'Change Location'" variant="primary" @click.stop="updateSelAsset(selAsset)"
					class="mr-1">
					<i class="fa fa-truck"></i>
				</b-button> -->

				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// Component
import AssetRowStatus from './AssetRowStatus';
import AssetChangeLogsDetailsView from './AssetChangeLogsDetailsView';

// Database
import assetDAO from '@/database/assets';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'asset-details-view',
	components: {
		AssetRowStatus,
		AssetChangeLogsDetailsView,
		Loading,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true,
		},
		isAccounting: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			selAsset: {},
			allUsersObj: {},
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		currentLocation() {
			if (this.selAsset && this.selAsset.currentLocation) {
				return this.selAsset.currentLocation;
			}
			return {};
		},
		details() {
			if (this.selAsset && this.selAsset.details) {
				return this.selAsset.details;
			}
			return {};
		},
		inventoriedBy() {
			let inventoriedBy = '';

			if (this.selAsset && this.selAsset.details) {
				let assetDetails = this.selAsset.details;

				if (!_.isEmpty(assetDetails.inventoriedBy)) {
					let user = this.allUsersObj[assetDetails.inventoriedBy];
					if (user && !_.isEmpty(user)) {
						inventoriedBy = user.firstName + ' ' + user.lastName;
						inventoriedBy =
							inventoriedBy + ' (' + assetDetails.inventoriedBy + ')';
					}
				}
			}
			return inventoriedBy;
		},
		remainingShelfLife() {
			let assetDetails = this.selAsset && this.selAsset.details ? this.selAsset.details : {};
			let expirationDate = assetDetails && assetDetails.expirationDate ? assetDetails.expirationDate : null;
			let remainingShelfLife = "-";

			if (expirationDate) {
				let days = DateUtil.getNoOfDays(DateUtil.getCurrentTimestamp(), expirationDate);
				if (days < 365) {
					remainingShelfLife = days + " day(s)";
				} else {
					let years = days / 365;
					remainingShelfLife = years.toFixed(2) + " year(s)";
				}
			}

			return remainingShelfLife;
		}
	},
	async mounted() {
		this.allUsersObj = { ...this.$store.getters.users };
		await this.retrieveSelAsset(this.row.item.assetCode);
	},
	methods: {
		async retrieveSelAsset(assetCode) {
			// show loading indicator
			this.isLoading = true;

			this.selAsset = await assetDAO.getAssetById(assetCode);

			// hide loading indicator
			this.isLoading = false;
		},
		showFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDate(date);
		},
		getNoOfDays(date) {
			return DateUtil.getNoOfDays(date, new Date());
		},
		updateSelAsset(item) {
			this.$store.commit('SET_CURR_ASSET', item);
			EventBus.$emit('onUpdateSelAsset', item);
		},
		getCondition(asset) {
			return asset.details && asset.details.condition ? asset.details.condition : "-"
		},
		getCategory(asset) {
			return asset.details && asset.details.category ? asset.details.category : "-"
		},
		hasAssetPhoto(selAsset) {
			return selAsset.status !== 'Inactive' && selAsset.status !== 'Lost'
				&& selAsset.details && selAsset.details.imgUrl;
		},
		getAssetPhotoUrl(selAsset) {
			let imgUrl = selAsset.details.imgUrl;

			if (!ValidationUtil.isValidURL(imgUrl)) {
				imgUrl = 'img/asset_placeholder.png';
			}

			return imgUrl;
		},
		getLastInventory(selAsset) {
			let lastInventory = "N/A";

			let details = selAsset && selAsset.details ? selAsset.details : {};
			if (details && details.inventorySessionId) {
				let inventorySessionId = details.inventorySessionId ? details.inventorySessionId : '-';
				let inventoryDate = details.lastInventoryDate ? this.showFormattedDateWithTime(details.lastInventoryDate) : '-';

				lastInventory = inventorySessionId + ' at ' + inventoryDate + ' by ' + this.inventoriedBy;
			}

			return lastInventory;
		},
		getDescription(selAsset) {
			let details = selAsset && selAsset.details ? selAsset.details : {};
			let description = details && details.description ? details.description : '';
			return description;
		},
		isAllowedToActivate() {
			return this.selAsset
				&& (this.selAsset.status === 'Inactive' || this.selAsset.status === 'Lost')
				&& !this.isViewer
		},
		isAllowedToUpdate() {
			return this.selAsset && this.selAsset.status === 'Stored' && !this.isViewer
		}
	},
};
</script>