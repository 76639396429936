<template>
	<b-modal id="activate-asset" title="Activate Asset" ref="modal" ok-title="Activate" @ok="handleOk" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-2">
					<b-col sm="12">
						<b> <i class="icon-target"></i> &nbsp;Set Initial Location </b>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col sm="12">
						<i class="icon-info"></i> &nbsp;
						<i>
							To activate asset
							<b-badge pill variant="primary">{{ selAsset.assetCode }}</b-badge>, please set its initial
							storage location.
						</i>
					</b-col>
				</b-row>

				<b-row class="my-3">
					<b-col sm="12">
						<b-form-group label="Company">
							<v-select name="Company" class="style-chooser" label="text" :options="companyOptions"
								:reduce="(company) => company.value" v-model="selCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">{{
								errors.first('Company')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Storage Location">
							<v-select name="Storage Location" class="style-chooser" label="text"
								:options="storageLocationOptions" :reduce="(loc) => loc.value" v-model="selStorageLocation"
								v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a storage location
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Storage Location')" class="help-block">{{
								errors.first('Storage Location')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Condition">
							<b-form-select name="Condition" v-model="selCondition" v-validate="'required'"
								:options="getAssetConditions(selAsset)" class="mr-2" />
							<span v-show="errors.has('Condition')" class="help-block">{{
								errors.first('Condition')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Activation Date">
							<date-range-picker opens="center" :maxDate="maxDate" :single-date-picker="true"
								:timePicker="true" :timePicker24Hour="false" v-model="selActivationDate" applyLabel="Apply"
								cancelLabel="Cancel" :style="{ width: '100%' }">
								<div slot="input">{{ activationDate }}</div>
							</date-range-picker>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { LocationUtil } from '@/utils/locationUtil';

// API
import assetApi from '@/api/assetApi';

// Others
import config from '@/config/env-constants';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'activate-asset',
	components: {
		Loading,
		DateRangePicker,
	},
	props: {
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		allAssetTypesObj: {
			type: Object,
			required: true,
		},
		allCompanyOptions: {
			type: Array,
			required: false,
		},
		allStorageLocationOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			companyOptions: [],
			storageLocationOptions: [],
			selCompany: config.companyDefaultValue,
			selStorageLocation: config.storageLocationDefaultValue,
			selCondition: config.assetCondition.GOOD,
			selActivationDate: DateUtil.getCurrentTimestamp(),
			selAsset: {},
			currUserId: this.$store.getters.loggedUser.id,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
			maxDate: new Date(
				DateUtil.endDateTimeStamp(DateUtil.getCurrentTimestamp())
			),
		};
	},
	watch: {
		selCompany: function () {
			if (this.selCompany.id) {
				this.retrieveStorageLocations();
			} else {
				this.resetStorageLocationEntries();
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		activationDate: {
			get() {
				return moment(this.selActivationDate.startDate).format(
					'MMMM D YYYY, h:mm a'
				);
			},
			set(value) {
				this.selActivationDate = value.startDate;
			},
		},
		timeStampedActivationDate() {
			return moment(this.selActivationDate.startDate).valueOf();
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelAsset', (selAsset) => {
			this.selAsset = selAsset;

			this.retrieveCompanyOptions();
			this.resetStorageLocationEntries();
		});
	},
	methods: {
		getAssetConditions(asset) {
			let conditions = [];

			let assetTypeId = asset.assetTypeId;
			let assetTypeObj = this.allAssetTypesObj[assetTypeId];

			if (assetTypeObj && assetTypeObj.conditions && !_.isEmpty(assetTypeObj.conditions)) {
				conditions = DropDownItemsUtil.retrieveAssetConditions(assetTypeObj.conditions);
			} else {
				conditions = config.assetConditionStatus;
			}

			return conditions;
		},

		retrieveCompanyOptions() {
			if (!this.isSuperAdmin) {
				let companies = {};
				companies[this.loggedUserCompany.id] = this.loggedUserCompany;
				this.companyOptions = DropDownItemsUtil.retrieveActiveCompanies(companies);
			} else {
				this.companyOptions = this.allCompanyOptions;
			}

			// init selected company
			this.selCompany = config.companyDefaultValue;
		},
		resetStorageLocationEntries() {
			// reset entries
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		retrieveStorageLocations() {
			let vm = this;

			this.resetStorageLocationEntries();

			this.allStorageLocationOptions.forEach((locOption) => {
				let loc = locOption.value;
				if (loc.companyId === vm.selCompany.id) {
					vm.storageLocationOptions.push({
						value: { id: loc.id, name: loc.name, geoaddress: loc.geoaddress },
						text: loc.name,
					});
				}
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			}

			this.handleSubmit();
		},

		prepareCurrentLocation() {
			return {
				company: this.selCompany.name,
				companyId: this.selCompany.id,
				storageLocation: this.selStorageLocation.name,
				storageLocationId: this.selStorageLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selStorageLocation.geoaddress
				),
				dateStored: DateUtil.getCurrentTimestamp(),
				storedBy: this.currUserId,
			};
		},
		getParam() {
			let param = {
				currUserId: this.currUserId,
				dateActivated: this.timeStampedActivationDate,
				currentLocation: this.prepareCurrentLocation(),
				assetCodes: [],
				assetConditions: {},
				operation: config.assetHistoryOperation.ACTIVATE_ASSET,
			};
			param.assetCodes.push(this.selAsset.assetCode);
			param.assetConditions[this.selAsset.assetCode] = this.selCondition;

			return param;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await assetApi.activateAssets(this.getParam());

				if (data.isSuccess) {
					let assetCode = this.selAsset.assetCode;
					this.$toaster.success(assetCode + ' was activated.');
					this.$refs.modal.hide();
					EventBus.$emit('onCloseSaveAssets', data.assets);
				} else {
					this.$toaster.warning(data.message);
				}
			} catch (_error) {
				this.$toaster.error('Error activating asset(s). Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			this.selCompany = config.companyDefaultValue;
			this.selStorageLocation = config.storageLocationDefaultValue;
			this.selActivationDate = DateUtil.getCurrentTimestamp();
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAsset');
	},
};
</script>