<template>
	<div>
		<span v-if="selAsset.status === 'Inactive'">
			<b-badge variant="secondary">{{ selAsset.status }}</b-badge>
		</span>
		<span v-if="selAsset.status == 'Stored'">
			<b-badge variant="primary">{{ selAsset.status }}</b-badge>
			<span v-if="selAsset.currentLocation && showFull">
				at {{ selAsset.currentLocation.company }} -
				{{ selAsset.currentLocation.storageLocation }}
			</span>
		</span>
		<span v-if="selAsset.status === 'In-Transit'">
			<b-badge variant="success">{{ selAsset.status }}</b-badge>
			<span v-if="selAsset.nextLocation && showFull">
				to {{ selAsset.nextLocation.company }} -
				{{ selAsset.nextLocation.storageLocation }}
			</span>
		</span>
		<span v-if="selAsset.status === 'In-Repair'">
			<b-badge variant="info">{{ selAsset.status }}</b-badge>
			<span v-if="selAsset.currentLocation && showFull">
				at {{ selAsset.currentLocation.company }} -
				{{ selAsset.currentLocation.storageLocation }}
			</span>
		</span>
		<span v-if="selAsset.status === 'Lost'">
			<b-badge variant="danger">{{ selAsset.status }}</b-badge>
			<span v-if="selAsset.currentLocation && showFull">
				at {{ selAsset.currentLocation.company }} -
				{{ selAsset.currentLocation.storageLocation }}
			</span>
		</span>
		<span v-if="selAsset.status === 'BER'">
			<b-badge variant="dark">{{ selAsset.status }}</b-badge>
		</span>
	</div>
</template>

<script>
export default {
	name: 'asset-row-status',
	props: {
		selAsset: {
			type: Object,
			required: true,
		},
		showFull: {
			type: Boolean,
			required: true,
		}
	},
};
</script>